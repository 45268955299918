import React from 'react'
//import {Link} from 'react-router-dom'
import Footer2 from './Footer2'
import Features from './Features'
//import cc2 from '../assets/cc2.png'
import cus from '../../assets/cus.png'
import main1 from '../../assets/c2.png'
import Tech from './Tech'
//import CompanyProfile from './CompanyProfile'

//import {Frame, Page, useCycle, Scroll, useMotionValue, useTransform } from 'framer'

//import BestSellers from '../components/home/BestSellers'
//import NewArrivals from '../components/home/NewArrivals'
//import BestItmes from '../components/home/BestItems'
//import SubCategoryList from '../components/subcategory/SubCategoryList'

//import CategoryList from '../components/category/CategoryList'
//import back1 from '../assets/wallpaper01.jpg'
//import logo from '../assets/logo.png'


const Home = () => {
  
  const cc_pic = 'https://i2.wp.com/www.adobomagazine.com/wp-content/uploads/2019/11/fb-hero.jpg?resize=1068%2C561&ssl=1' 
  const vc_pic = 'https://miro.medium.com/max/700/1*RSClt6Kr5u943UaLPgHeZg.png' 
  //const tc_pic =  'https://miro.medium.com/max/700/1*tijYDzC59_wasLntTiBeSg.png' 
  //const tc_pic2 =  'https://cdn-media-1.freecodecamp.org/images/1*gILubZM4zMQnVf4CAm-NSA.jpeg' 

  const basic_pic2 = 'https://www.whisbi.com/wp-content/uploads/2016/11/old-blog-18.jpg' 
  // const basic_pic3 = 'https://cdn.business2community.com/wp-content/uploads/2016/12/Untitled-300x180.png.png' 
  // const basic_pic4 = 'https://res.cloudinary.com/stq/image/upload/v1608016511/cqlxohl8sqjidopnulg6.jpg' 
  // const basic_pic5 = 'https://res.cloudinary.com/stq/image/upload/v1608017534/r1qaub3yohlzyoioklks.jpg' 
  // const basic_pic6 = 'https://res.cloudinary.com/stq/image/upload/v1608017404/vzpmugzhxpqm8ptld7r7.jpg' 

 

    return (

      <div className='relative w-full dark:text-gray-200'>

   
  
      <section className="relative flex justify-center w-full p-4">

          <div className=' md:w-full lg:max-w-7xl w-full '>    
         
            <div className="flex flex-col md:flex-row lg:h-72 mt-4 p-4 ring-2 ring-brown-400 w-full mb-8 rounded">
                <div className=' flex flex-col justify-center md:ml-8'>
                <h1 className='p-4 w-full text-xl md:text-3xl opacity-100 text-center lg:text-left'> 
                <span className='font-semibold'>STQM</span> is a next-generation messaging and chatting platform
                </h1>
                <p className='p-4 md:text-xl'> 
                SpacetimeQ provides a powerful, easy-to-use, web-based messaging and chatting platform developed by the advanced react
                design system with Firebase, Redux toolkit, Node.js, and typescript. Our solutions and services will truly integrate into 
                the web ecosystem for all users and businesses to make their online life better.
                </p>
                {/* <a href='https://api.spacetimeq.com:2130/'> 
                    <button className='w-48 m-4 py-2 my-4 md:py-3 ring-2 ring-gray-400 lg:mr-6
                                    dark:hover:bg-blue-900 dark:hover:ring-brown-200 uppercase rounded'>
                        Try Trial
                    </button>
                </a> */}
                </div>

            </div>
    
            <div className="flex flex-col md:flex-row lg:h-72 p-4 ring-2 ring-brown-400 w-full mb-8 rounded">
                <div className='w-full md:w-1/2  flex flex-col lg:flex-row items-center justify-around sm:my-4  lg:mr-8'>
                <Tech />
                </div>
                <div className='md:w-1/2 flex flex-col justify-center md:ml-8'>

                <span className='text-center md:text-left text-xl font-semibold lg:text-3xl my-4'> 
                Modern Scalable Web Technologies
                </span>
              </div>


            </div>

            <div className="flex flex-col md:flex-row  p-4 ring-2 ring-brown-400 w-full mb-8 rounded">
              <div className='md:w-1/2 flex flex-col justify-center md:ml-8'>
                <h1 className='text-center md:text-left text-lg md:text-2xl'> 
                  The Best platform for 
                </h1>
                <span className='text-center mb-4 md:text-left text-xl font-semibold lg:text-3xl'> 
                    Conversational Commerce
                </span>
                <p className='mt-2 mr-8 mb-2 text-center md:text-left lg:text-lg'>
                refers to the intersection of messaging apps and shopping. Meaning, the trend toward interacting 
                with businesses through messaging and chat apps
                </p>
              </div>

              <div className='md:w-1/2 mb-8 mt-8 flex flex-col lg:flex-row items-center justify-around  lg:mr-8'>

                    <img className='h-56 mt-4 lg:h-64 rounded-lg' src={cc_pic} alt='ccmmrce pic' />
    
              </div>
            </div>

            <div className="flex flex-col md:flex-row  p-4 ring-2 ring-brown-400 w-full mb-8 rounded">


                <div className='w-full md:w-1/2  flex flex-col lg:flex-row items-center justify-around sm:my-8 lg:mr-8'>
                <img className='p-2  h-72 lg:h-72 rounded-lg' src={vc_pic} alt='ccmmrce pic' />
                </div>

                <div className='w-full md:w-1/2 flex flex-col justify-center md:ml-8'>
                    <h1 className='text-center md:text-left  text-lg md:text-2xl'> 
                    Integrated with
                    </h1>
                    <span className='text-center md:text-left text-xl font-semibold lg:text-3xl'> 
                       PC & Mobile Video Live Commerce
                    </span>
                    <p className='mb-4 text-center md:text-left'>
                        like TV Home Shopping Channel
                    </p>

                    <p className='mt-2 mr-8 text-lg text-center md:text-left'>
                    It used to describe the combination of streaming video and e-commerce, promises to revolutionize the retail 
                    industry and consumer shopping habits. Here’s a rundown of how the live & video commerce is taking shape, trends in the retail
                    space
                    </p>
                </div>
            </div>


            <div className="w-full flex flex-col   ring-2 ring-brown-400 mb-8 rounded">

              <div className='flex flex-col md:flex-row'>
 

                <div className='lg:ml-16 w-full m-1 lg:w-6/12'>

                <h1 className='text-center lg:text-left mt-8 mr-4 text-md  md:text-2xl'>
                  A beautiful, elegant, and fun visual presentation 
                </h1>
                <h1 className='md:text-3xl font-semibold mb-4  text-center lg:text-left uppercase'>
                STQ Web Navigator         
                </h1>

                <p className='text-md lg:text-lg text-center mr-8 lg:text-left mb-4'>
                We are building STQ(Space, Time, Cube) Web Navigator. It is a beautiful, elegant, and fun visual presentation 
                and personal data and assets management tool. Its presentation consists of Space & Time Calendar, My Life-Spectrum 
                Scheduler, and Kanban Card System integrated with Zettelkasten Method. 
                </p>

                <p className='text-md text-center lg:text-left lg:text-lg mb-8 mr-8'>
                STQ Navigator will gather and manage all users’ data, and
                preferences based on relational links, locations and time. It is also a platform by which users can select a location
                and time-related events or information that they want to see. 
                </p>

                </div>

                <div className='w-full lg:w-6/12 mb-8 mt-8  flex flex-col justify-center items-center'>
                  <img className='h-48 w-96 rounded-lg' src={basic_pic2} alt='ccmmrce pic' />
                  <img className='h-48 w-96 rounded-lg' src={cus} alt='ccmmrce pic' />
                </div> 
              </div>

            </div>

            <div className="w-full flex flex-col justify-center items-center  ring-brown-400 mb-4 rounded">
              <Features />
            </div>

            <div className="mt-8 flex flex-col lg:flex-row  p-4 ring-2 ring-brown-400 w-full mb-8 rounded">
                <div className='w-full lg:w-6/12  flex flex-col lg:flex-row items-center justify-center'>
                    <img className='my-8 rounded-xl h-72  ' src={main1} alt='ccmmrce pic' />
                </div>
                <div className='w-full lg:w-6/12 flex flex-col justify-center md:ml-8'>
                    <h1 className='md:ml-8 mt-8  w-full text-xl md:text-3xl text-center lg:text-left opacity-100'> 
                    About SpacetimeQ Inc,
                    </h1>
                    <p className='md:ml-8  mt-4 mr-4 mb-4 text-sm md:text-lg text-center lg:text-left'> 
                    SpacetimeQ Inc provides a chatting and messaging platform that unleashes the creative genius inside everyone. 
                    STQ’s platform makes it easy to use, make diversify the online community by chatting and messaging platform with 
                    several dynamic tools. Also, this platform provides conversational e-commerce and video live e-commerce functionalities 
                    for businesses and customers to sell various styles of goods and services. SpacetimeQ Inc. was founded by Soomin Kim and 
                    James Chung in 2018. The headquarter is located in San Ramon California, and Research and development office is 
                    located in Yokohama Japan.
                    </p>
                </div>

            </div>

            <Footer2 />
            </div>

 
        </section>
      </div>



    )
}

export default Home
