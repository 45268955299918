/*=============================================================================
 Layout.tsx - layout

 -------------------------------------------------------------------------
 | = | <Navbar>
 -------------------------------------------------------------------------
 | <Sidebar> | <Main>
 |           |

 - <Sidebar> can be opened in two modes:
   1. overlapped mode (dimming underlying contents)
   2. push mode: pushing the main contents (without dimming)

 - Let <Sidebar> handle the effects of other elements such as dimming and pushing away:
   so need to hand over the ref of <Main> to <Sidebar>

 (C) 2021 SpacetimeQ INC.
=============================================================================*/
import { useRef } from 'react';
import { BrowserRouter, Switch, Route, Redirect, } from 'react-router-dom';
import Navbar  from './Navbar';
//import Sidebar from './Sidebar';
import { RouteCompos } from './Routes';

// const BandSpacetimeQ = () =>
//   <h1 className="dark:text-yellow-400 dark:bg-gray-800 text-black bg-gray-200 font-mono font-extrabold text-lg text-center p-1 mb-1">
//     <span className="dark:text-blue-400 text-blue-700 Text-shadow">Space</span>
//     <span className="dark:text-green-400 text-green-700 Text-shadow">time</span>
//     <span className="dark:text-red-400 text-red-700 Text-shadow">Q</span>
//     <span className="ml-2 text-center font-normal font-mono dark:text-yellow-400 text-gray-800">
//       - UI Themes Lab (tailwindcss / sass)
//     </span>
//   </h1>;

export default function Layout() {
  const ref = useRef<HTMLDivElement>(null);  // allow <Sidebar> to handle <div> with ref
  return (
    <BrowserRouter>
  
      <Navbar />
      {/* <Sidebar refPush={ref} /> */}
      <div
        {...{ref}}
        className="transform transition-spacing ease-in-out duration-300 sm:duration-500"
      >

        <Switch>
          {RouteCompos.map((rt, i) => <Route key={i} exact path={rt.path}>{rt.children}</Route>)}
          <Redirect to="/" />
        </Switch>
      </div>
   
    </BrowserRouter>
  );
}
