import React from 'react'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
//import ScheduleIcon from '@material-ui/icons/Schedule';

const Features = () => {
    return (
        <div>
            <div className=" p-4 grid grid-cols-1  lg:grid-cols-2 xl:grid-cols-2  gap-8 justify-items-center">
           
                    <div className='w-full    flex flex-col justify-start ring-2 ring-brown-400 rounded'>
                        <div className='px-8 py-4 lg:h-32  dark:bg-gray-800 bg-gray-300 rounded-t-md'>
                            <span className='text-xl'>
                                <BlurCircularIcon className='' style={{ fontSize: 40 }} />
                            </span>
                            <h1 className='mt-2 text-xl text-center lg:text-left'>
                                SpaceTime Calendar
                            </h1>
                        </div>
                        <div className='px-8 pt-4 pb-8 flex flex-row justify-around text-center lg:text-left'>
                        Spacetime Calendar provides truly beautiful, featuring elegant web services to recreate the flexible 
                        viewer of personal schedule in an online form. That means you can use each day's slot in a variety of ways, 
                        including sharing your notes with other users and work on them collaboratively. Also, this app collaborates with 
                        important public holidays and event information base on international locale.              
                        </div>
                    </div>

          
                    <div className='w-full flex flex-col justify-center ring-2 ring-brown-400 rounded'>
                        <div className='px-8 py-4 lg:h-32 dark:bg-gray-800 bg-gray-300 rounded-t-md'>
                            <span className='text-xl'>
                                <SpeakerNotesIcon className='' style={{ fontSize: 40 }} />
                            </span>
                            <h1 className='text-xl text-center lg:text-left'>
                                Kanban Card System integrated with Zettelkasten Method                           
                            </h1>
                        </div>
                        <div className='px-8 pt-4 pb-8 flex flex-row justify-around'>
                            Kanban Card System works as a system, how to create notes, how to connect notes to make a web of 
                            knowledge, and how to create structures so you stay in control of the growing personal data
                            management like favorite links, pictures, and other information on the web. Also, it is about 
                            optimizing your workflow of learning and producing 
                            knowledge, creating value-added data for a better online lifestyle. 
                        </div>
                
                </div>
            </div>
        </div>
    )
}

export default Features
