import React from 'react'
import {Link} from 'react-router-dom'
import Qlogo from '../../assets/Qlogo.png'




export const Footer = () => {
    return (
    <div className='mt-6  sm:hidden'>
    <footer className="footer relative pt-1 border-b-2 border-blue-700 ring-2 ring-brown-400 rounded">
        <div className="flex flex-col lg:flex-row  p-4 ">
            <div className="w-full flex mt-6">
                <div className='w-5/12 hidden md:block'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className='flex ml-4  justify-center items-center'>
                            <img className='mx-8 w-20 opacity-50' src={Qlogo} alt='logo'/>
                            </div>

                            <div className='ml-6 mt-2'>
                                <h1 className='font-semibold uppercase'>SpacetimeQ Inc.</h1>
                                <p className='text-sm mt-1'> 416 Santander Dr. San Ramon CA, 94518 USA</p>
                                <p className='text-sm mt-2'>R&D Office in Japan</p>
                                <p className='text-sm mt-1'> Ocean Gate Minatomirai 8F 3-7-1 Minatomirai, </p>
                                <p className='text-sm mt-1'> Nishi-ku,Yokohama 220-0012, JAPAN</p>

                            </div>
                        </div>

                        <div className='px-1 mt-6 ml-40 md:w-48 lg:w-96  flex justify-around text-xl '>
                            <i className="fab fa-facebook-square"></i>
                            <i className="mx-3 fab fa-google"></i>
                            <i className="mx-3 fab fa-instagram"></i>
                            <i className="mx-3 fab fa-github"></i>
                            <i className="ml-3 mr-8 fab fa-twitter-square"></i>
                        </div>                    
                    </div>

                    
                </div>
            </div>
        </div>

        <div className="container mx-auto px-6">
            <div className="mt-8 border-t-2 border-gray-300 flex flex-col items-center">
                <div className="sm:w-2/3 text-center py-6">
                    <p className="text-sm  font-bold mb-2">
                        © 2021 SpacetimeQ Inc.
                    </p>
                </div>
            </div>
        </div>
    </footer>


    
    </div>
    )
}


const Footer2 = () => {
    return (
    <div>

 
    <div className='mt-6 hidden md:block'>
    <footer className="footer relative pt-1 border-b-2 border-blue-700 ring-2 ring-brown-400 rounded">
        <div className="flex flex-col lg:flex-row  p-4 ">
            <div className="w-full flex mt-6">
                <div className='lg:w-6/12 xl:w-7/12'>
                    <div className='flex flex-col'>
                        <div className='flex flex-row'>
                            <div className='flex ml-4  justify-center items-center'>
                            <img className='mx-4 w-20 opacity-50' src={Qlogo} alt='logo'/>
                            </div>

                            <div className='ml-6 mt-2 lg:mt-8'>
                                <h1 className='font-semibold uppercase'>SpacetimeQ Inc.</h1>
                                <p className='text-sm mt-1'> 416 Santander Dr. San Ramon CA, 94518 USA</p>

                                <p className='text-sm mt-6'>R&D Office in Japan</p>
                                <p className='text-sm mt-1'> Ocean Gate Minatomirai 8F 3-7-1 Minatomirai, </p>
                                <p className='text-sm mt-1'> Nishi-ku,Yokohama 220-0012, JAPAN</p>

                            </div>
                        </div>

                        <div className='px-1 mt-6 lg:ml-32 md:w-48 lg:w-72 xl:w-96 flex justify-around text-xl '>
                            <i className="opacity-30 fab fa-facebook-square"></i>
                            <i className="opacity-30 fab fa-google"></i>
                            <i className="opacity-30 fab fa-instagram"></i>
                            <i className="opacity-30 fab fa-github"></i>
                            <i className="opacity-30 fab fa-twitter-square"></i>
                        </div>                    
                    </div>

                    
                </div>

                    <div className="w-full lg:w-6/12 xl:w-7/12 mt-8 mr-4 flex flex-col md:flex-row justify-around">
                        <div className="flex flex-col">
                            <span className="font-bold text-brown-500 uppercase mb-2">Solution</span>
                            <span className="my-2"><Link to="#" className="  text-md ">E-Commerce</Link></span>
                            <span className="my-2"><Link to="#" className="  text-md ">STQ Navigator </Link></span>
                            <span className="my-2"><Link to="#" className="  text-md ">Dashboard</Link></span>
                            <span className="my-2"><Link to="#" className="  text-md ">Insight</Link></span>
                        </div>
                        <div className="flex flex-col">
                            <span className="font-bold text-brown-500 uppercase mt-4 md:mt-0 mb-2">Support</span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Pricing</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Documentation</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Guides</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">API Status</Link></span>
                        </div>
                        <div className="flex flex-col">
                            <span className="font-bold text-brown-500 uppercase mt-4 md:mt-0 mb-2">Company</span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">About</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Blog</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Jobs</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Press</Link></span>
                        </div>
                        <div className="flex flex-col">
                            <span className="font-bold text-brown-500 uppercase mt-4 md:mt-0 mb-2">Legal</span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Claim</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Privacy</Link></span>
                            <span className="my-2"><Link to="#" className="opacity-30 italic text-md ">Terms</Link></span>
                        </div>
                    </div>
                </div>

            </div>

        <div className="container mx-auto px-6">
            <div className="mt-8 border-t-2 border-gray-300 flex flex-col items-center">
                <div className="sm:w-2/3 text-center py-6">
                    <p className="text-sm  font-bold mb-2">
                        © 2021 SpacetimeQ Inc.
                    </p>
                </div>
            </div>
        </div>
    </footer>

    </div>  

    <div className='md:hidden'>
        <div className=' flex flex-col ring-2 ring-brown-400 rounded'>
            <div className='flex flex-col'>
                <div className='flex ml-1 mt-8 mb-4  justify-center items-center'>
                    <img className='mx-8 w-16 opacity-50' src={Qlogo} alt='logo'/>
                </div>

                <div className='ml-2 mt-2 text-center'>
                    <h1 className='font-semibold uppercase'>SpacetimeQ Inc.</h1>
                    <p className='text-sm mt-1'> 416 Santander Dr. San Ramon CA, 94518 USA</p>

                    <p className='text-sm mt-2'>R&D Office in Japan</p>
                    <p className='text-sm mt-1'> Ocean Gate Minatomirai 8F 3-7-1 Minatomirai, </p>
                    <p className='text-sm mt-1'> Nishi-ku,Yokohama 220-0012, JAPAN</p>

                </div>
            </div>

            <div className='px-12 my-6 w-full flex justify-around text-xl text-center'>
                <i className="fab fa-facebook-square"></i>
                <i className="fab fa-google"></i>
                <i className="fab fa-instagram"></i>
                <i className="fab fa-github"></i>
                <i className="fab fa-twitter-square"></i>
            </div>                    
        
            <div className="container mx-auto px-6">
                <div className="mt-8 border-t-2 border-gray-300 flex flex-col items-center">
                    <div className="sm:w-2/3 text-center py-6">
                        <p className="text-sm  font-bold mb-2">
                            © 2021 SpacetimeQ Inc.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

 
    </div>
    )
}

export default Footer2
