/*=============================================================================
 Navbar.tsx - Navigation Bar Menu

 - fixed (position: fixed;) prevents the scrolling out of the top navbar
 - then insert <div className="h-10" /> to compensate for the relative height
 - To prevent menu text wrapping, use whitespace-nowrap

 (C) 2021 SpacetimeQ INC
=============================================================================*/
//import { useState } from 'react';
//import { NavLink }          from 'react-router-dom';
//import { RouteMenus }       from './Routes';
import { ToggleDarkButton } from 'ui/toggleDark';
import { cLo, dbg } from 'utils/util';
//import { NotiIconButton } from 'ui/ui';
import logo2 from '../../assets/Qlogo.png'

export default function Navbar() {
  //const [notis, setNotis] = useState<number>(12);  // notifications count
  return (<>
    <nav
      {...cLo("fixed flex flex-col z-20 w-full h-14 shadow-lg",
        "dark:bg-gray-600 dark:text-gray-100",
        "bg-gray-200 text-gray-800")}
    >
        {/* <div {...cLo('hidden sm:block')} > 
        
      <ul {...cLo("ml-24 w-3/4 flex justify-center items-center", dbg(" border-red-800"))}>
        {RouteMenus.filter(r => r.level === 0).map(m =>
          <li key={m.to} {...cLo("mt-2 whitespace-nowrap", dbg("border border-dashed border-blue-400"))}>
            <NavLink
              exact to={m.to}
              activeClassName="bg-black text-white rounded-md p-1"
              {...cLo(dbg("border border-pink-400"))}
            >
              {m.title}
            </NavLink>
          </li>
        )}
      </ul>
      </div> */}

      <div className='mt-3 ml-4 flex flex-row'>
      <img className='h-8' src={logo2} alt='logo' />
      <h1 className='mt-1 ml-4 uppercase font-semibold text-lg'>SpacetimeQ</h1>
      </div>
      <div className="mt-2 absolute top-1 right-0 flex flex-row">
        {/* <NotiIconButton
          notis={notis}
          isSelected={notis > 0}
          onPress={() => setNotis(notis > 0 ? notis - 1: 0)}
        /> */}
        <ToggleDarkButton className="mx-2 my-1" />

      </div>
    </nav>
    <div className="h-10" />
  </>);
}
