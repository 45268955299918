/*=============================================================================
 Routes.tsx - Route Definitions for Menu Labels and Components

 - used by <NavLink> and <Route>
 - Not all route paths are mapped to the menu,
   but all route paths should have a handling component

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { RouteProps } from 'react-router-dom';
// ----------------------------------------------------------------------------
// Components for each routed page
// ----------------------------------------------------------------------------
// import BasicPage   from 'app/BasicPage';
// import MoreForms   from 'app/MoreForms';
// import DnDFiles    from 'app/DnDFiles';
// import DnDFilesLib from 'app/DnDFilesLib';
// import DnDItems    from 'app/DnDItems';
// import DnDKanban   from 'app/DnDKanban';
// import CamTest     from 'app/CamTest';
// import LocLab      from 'app/LocLab';
// import Avatars     from 'app/Avatars';
//import Quiz        from 'app/Quiz';
import Home           from 'app/home/Home';
// import Chat           from 'app/Chat/chat';
// import Users           from 'app/users/users';
// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------
// All Route paths
// ----------------------------------------------------------------------------
const ROUTES = {
  root:      "/",
  // moreforms: "/moreforms",
  // dndflib:   "/dndflib",
  // dndfiles:  "/dndfiles",
  // dnditems:  "/dnditems",
  // dndkanban: "/dndkanban",
  // webcam:    "/webcam",
  // loclab:    "/loclab",
  // avatars:   "/avatars",
  // quiz:      "/quiz",
  // chat:      "/chat",
  // users:      "/users",
};

// ----------------------------------------------------------------------------
// Route - Component map for react-router's <Route>
// All routes should have a mapped component.
// ----------------------------------------------------------------------------
export const RouteCompos: Partial<RouteProps>[] = [
  { path: ROUTES.root,      children: <Home   /> },
  // { path: ROUTES.chat,      children: <Chat       /> },
  // { path: ROUTES.users,      children: <Users       /> },
  // { path: ROUTES.moreforms, children: <MoreForms   /> },
  // { path: ROUTES.dndflib,   children: <DnDFilesLib /> },
  // { path: ROUTES.dndfiles,  children: <DnDFiles    /> },
  // { path: ROUTES.dnditems,  children: <DnDItems    /> },
  // { path: ROUTES.dndkanban, children: <DnDKanban   /> },
  // { path: ROUTES.webcam,    children: <CamTest     /> },
  // { path: ROUTES.loclab,    children: <LocLab      /> },
  // { path: ROUTES.avatars,   children: <Avatars     /> },
  // { path: ROUTES.quiz,      children: <Quiz        /> },
];

// ----------------------------------------------------------------------------
// Route - Menu map for react-router's <Link> or <NavLink>
// Some routes can be linked to the menu.
// level: to filter menus
// ----------------------------------------------------------------------------
export const RouteMenus = [
  { to: ROUTES.root,      title: "Home",      level: 0},
  // { to: ROUTES.chat,      title: "Chat",      level: 0},
  // { to: ROUTES.users,      title: "Users",      level: 0},
  // { to: ROUTES.moreforms, title: "More Forms",     level: 1},
  // { to: ROUTES.dndflib,   title: "react-dropzone", level: 0},
  // { to: ROUTES.dndfiles,  title: "DnD Files",      level: 1},
  // { to: ROUTES.dnditems,  title: "DnD Items",      level: 0},
  // { to: ROUTES.dndkanban, title: "Kanban",         level: 0},
  // { to: ROUTES.webcam,    title: "Webam",          level: 0},
  // { to: ROUTES.loclab,    title: "LocLab",         level: 1},
  // { to: ROUTES.avatars,   title: "Avatars",        level: 1},
  // { to: ROUTES.quiz,      title: "Quiz",           level: 1},
];
