/*=============================================================================
 toggleSlider.tsx - toggle UI tools

 (C) 2021 SpacetimeQ INC
=============================================================================*/
import { useRef } from 'react';
import { cCo, cL, cLo } from 'utils/util';
import { twButtonPressed } from 'ui/ui';
import { useToggleState, }        from '@react-stately/toggle';
import { useToggleButton, }       from '@react-aria/button';
import { AriaToggleButtonProps }  from '@react-types/button';

// Toggling Dot button to enable/disable slider
// On: Prefers Media (Auto, disabled slider) mode, Off: Manual (use slider) mode
export interface IToggleDotProps extends AriaToggleButtonProps<React.ElementType> {
  bgcolors: {
    on:  TailwindColor<'bg'>,
    off: TailwindColor<'bg'>
  }
};
export function ToggleDot({
  bgcolors,
  ...props
}: IToggleDotProps
) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  return (
    <button
      {...{ref}}
      role="switch"
      aria-checked={state.isSelected}
      tabIndex={0}
      {...buttonProps}
      {...cCo(cL("w-2.5 h-2.5 rounded-full mr-1.5 Ball-shadow focus:outline-none border border-gray-500",
          twButtonPressed(isPressed)),
        state.isSelected, bgcolors.on, bgcolors.off
      )}
    />
  );
}

// slider: Change of the position by justify-end cannot be animated with transition.
// 'translate' of the slider can be animated but how can we figure out the amount?
export interface IToggleSliderProps extends AriaToggleButtonProps<React.ElementType> {
  colors?: string0;       // button colors
  srOnly?: string0;       // screen reader only
  size?:   TSizeVariants; // slider/ball size
};
export function ToggleSlider({
  colors, srOnly, size = 'md',
  ...props
}: IToggleSliderProps
) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const sz = {  // size dependent adjustment
    sm: { slide: "w-7 h-4",  ball: "w-3 h-3", dx: "translate-x-3" },
    md: { slide: "w-11 h-6", ball: "w-5 h-5", dx: "translate-x-5" },
    lg: { slide: "w-14 h-8", ball: "w-7 h-7", dx: "translate-x-6" },
  };
  return (
    <button
      {...{ref}}
      role="switch"
      aria-checked={state.isSelected}
      tabIndex={1}
      {...buttonProps}
      {...cLo("inline-flex items-center px-0.5 rounded-full", sz[size].slide,
        "transition-colors duration-200 focus:outline-none Inner-shadow", colors)}
    >
      {srOnly && <span className="sr-only">{srOnly}</span>}
      {props.children}
      <span
        {...cLo("absolute rounded-full border",
          props.isDisabled
            ? "border-gray-800 bg-gray-500 Ball-shadow"
            : "border-gray-400 bg-white Ball-shadow",
          "transition transform duration-300 ease-in-out",
          state.isSelected && sz[size].dx, sz[size].ball,
          twButtonPressed(isPressed)
        )}
        style={{ transformOrigin: "50% 50% 0px" }}
      />
    </button>
  );
}
