import React from 'react'
import firebase from '../../assets/tech/firebase.svg'
import reactsvg from '../../assets/tech/react.svg'
import nodejs from '../../assets/tech/nodejs.svg'
import redux from '../../assets/tech/redux.svg'
import webrtc from '../../assets/tech/webrtc.svg'
import ts from '../../assets/tech/ts.svg'
import tailwindcss from '../../assets/tech/tailwindcss.svg'
import sass from '../../assets/tech/sass.svg'

const Tech = () => {
    return (
        <div className='flex flex-col w-full  justify-center items-center'>
            <div className='flex flex-row h-18'>
                <img className='w-16 h-16 m-2' src={reactsvg} alt='react' /> 
                <img className='w-16 h-16 m-2' src={redux} alt='redux' />
                <img className='w-14 h-16 m-2' src={firebase} alt='firebase' />
                <img className='w-16 h-16 m-2' src={ts} alt='ts' />
            </div>

            <div className='flex flex-row h-18'>
                <img className='w-16 h-16 m-2' src={nodejs} alt='nodejs' />
                <img className='w-16 h-16 m-2' src={webrtc} alt='webrtc' />
                <img className='w-16 h-16 m-2' src={tailwindcss} alt='tailwindcss' />
                <img className='w-16 h-16 m-2' src={sass} alt='sass' />
            </div>

        </div>
    )
}

export default Tech
