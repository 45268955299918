/*=============================================================================
 App.tsx - App Main

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import 'styles/App.scss';
import Layout from 'ui/layout/Layout';
import { DarkThemeProvider, } from './darkThemeCtx';

function App() {
  return (
    <DarkThemeProvider>
      <Layout />
    </DarkThemeProvider>
  );
}

export default App;
// Application must be wrapped in an OverlayProvider so that it can be
// hidden from screen readers when a modal opens.
