/*=============================================================================
 util.ts - general utility functions

 - For debugging, attach "border border-pink-400 " to the c_base.
 - Regex for the function call and the whole arguments:
    (c[C|L]0?o?)\(\s*([^)]+?)\s*\)

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { IError } from 'common';

const DEVELOPMENT_MODE: boolean =
  !process.env.NODE_ENV ||
   process.env.NODE_ENV === 'development';

export function isDev() {
  return DEVELOPMENT_MODE;
}

export function dbg(text: string) {  // debug text
  return DEVELOPMENT_MODE ? text : null;
}

export function addIf(
  cnd_if: Maybe<string | boolean>,
  c_then: string
) {
  return cnd_if ? c_then : '';
}

// ----------------------------------------------------------------------------
// className Utilities - Purposes (mainly for tailwindcss' long list of classNames)
// ----------------------------------------------------------------------------
// 1. Combine list of strings, no need to care for the delimeter ' ' between strings.
// 2. Conditional expressions such as {mobile && 'ml-4'} can be seamlessly added.
//    (React className string cannot be concatenated with null or false expressions.)
// 3. Using the Object { className: ... } and the spread operator (...), "className=" can be removed.
type TCondClassF<T = TClassName> = (
  c_base:  TClassName,   // base classname
  cnd_if:  Maybe<string | boolean>,  // if the condition met
  c_then:  TClassName,   // concatenate
  c_else?: TClassName    // (optional) otherwise
) => T;

// className Conditional
// Concatenate class names only if the provided condition is met
// and return the className object so that it can be used with the spread syntax
// --------------------------------------------------
//   className={cC()}  --->  {...cCo()}
// --------------------------------------------------
export const cC: TCondClassF = (   // class with options (if-then-else)
  c_base, cnd_if, c_then, c_else
) => {
  const c_prefix = c_base ? c_base + ' ' : '';  // handle c_base Nullish case
  if (cnd_if) return (c_prefix + c_then);
  if (c_else) return (c_prefix + c_else);  // if the third option was given
  return              c_base || '';
}

// className Conditional object
export const cCo: TCondClassF<IClassNameObj> = (   // class with options (if-then-else)
  c_base, cnd_if, c_then, c_else
) => ({ className: cC(c_base, cnd_if, c_then, c_else) });

// className Conditional 0(no base) object
export const cC0o = (
  cnd_if:  Maybe<string | boolean>,  // if the condition met
  c_then:  TClassName,   // concatenate
  c_else?: TClassName    // (optional) otherwise
): IClassNameObj => ({ className: cnd_if ? c_then : c_else });

// class List
// --------------------------------------------------
//  className={"m-1 text-gray-400 " + (cls || "")} can be written as:
//  className={cL('m-1', 'text-gray-400', cls)}
// --------------------------------------------------
export function cL(
  ...clss: TClassName0[]  // rest parameters, list of classNames allowing null or boolean
): TClassName {
  return clss.reduce((cum, a) => a  // check this add
    ? cum               // if valid previous (cumulative) string
      ? cum + ' ' + a   //   concatenate with the delimeter ' '
      : a               // no valid previous string
    : cum               // no valid a
  ) || undefined;       // Only undefined is allowed in nullish expressions in React
}

// class List object: to omit 'className=' using spread attributes
// --------------------------------------------------
//   {...cLo('m-1', 'text-gray-400', cls)}
// --------------------------------------------------
export function cLo(
  ...clss: TClassName0[]
): IClassNameObj {
  return { className: cL(...clss) };
}
// ----------------------------------------------------------------------------
// conditional string concatenation (cL's string version, not classNames)
// --------------------------------------------------
export function cS(
  ...lstr: Maybe<string | false>[]  // rest parameters, list of strings allowing null or boolean
): Undefinable<string> {
  return lstr.reduce((cum, a) => a  // check this add
    ? cum          // if valid previous (cumulative) string
      ? cum + a    //   concatenate with no delimeter
      : a          // no valid previous string
    : cum          // no valid a
  ) || undefined;  // Only undefined is allowed in nullish expressions in React
}
// ----------------------------------------------------------------------------

// number to leadingZero string
// or use n.toString().padStart(2, "0");  // ES6
export const lZ = (n: number) =>
  (n < 10)
    ? '0' + n
    : n.toString();  // leading Zero making a two digit number string

// time String
export const timeStr = (d: string | number | Date) => {
  const dt = new Date(d);
  return lZ(dt.getHours()) + ':' +
         lZ(dt.getMinutes());  // JST=UTC+9
}

let gLastUniqueId = 1000;  // unique within this app's instance'
export const genUniqueId = (prefix: string = 'stq') => {
  gLastUniqueId++;
  return `${prefix}${gLastUniqueId.toString()}`;
}

// --------------------------------------------------
// Regex
// --------------------------------------------------
export const REGEX_Email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const sec2hms = (sec: number) => {
  if (sec < 60) {
    return sec.toFixed(1) + 's';
  }
  let hstr: string = '';
  let s = Math.trunc(sec);
  const h = Math.trunc(s/3600);
  if (h > 0) {
    s -= h*3600;
    hstr = h + ':';
  }
  const m = Math.trunc(s/60);
  s -= m*60;
  return hstr + lZ(m) + ':' + lZ(s);
}

// const callifdef = (f, arg) => if (f && typeof f === "function") f(arg);

export function toggleValue<T = string>(val: T, a: T, b: T): T {
  return (val === a) ? b : a;
}

// returns IError defined in 'common.d.ts', errorObject
export const errorO = (code: string, message: string): IError => ({ code, message });

export const hideAttr = (hide: Undefinable<boolean>) => hide ? 'hidden' : 'block';
export const showAttr = (show: Undefinable<boolean>) => hideAttr(!show);

// for use as <a {...OPEN_NEW_WINDOW}>
export const OPEN_NEW_WINDOW = {
  target: "_blank",
  rel:    "noopener noreferrer"
};
