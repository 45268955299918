/*=============================================================================
 toggleDark.tsx - description

 - Removed useState from the button, let the parent keep and handle the state:
   stateless buttons
 - Just propagate onClick event, a callback from the parent
 - using React ContextAPI, let the <ToggleDarkButton> be active in itself

 (C) 2020 SpacetimeQ INC
=============================================================================*/
import { useDarkThemeCtx } from 'app/darkThemeCtx';
import { cL, cLo } from 'utils/util';
import { RULE_EVENODD, squareView, getPathD, SvgIconFull, } from 'utils/svg';
import { ToggleDot, IToggleSliderProps, ToggleSlider } from 'ui/toggleSlider';

// Left Button switch to enable/disable "media" mode (that prefers media)
// ToggleSlider to force "dark"/"light" mode
export const ToggleDarkButton = ({
  className: cn,
  colors    = "bg-gray-500",
  size      = 'md',
}: Partial<IClassNameObj> &
  Pick<IToggleSliderProps, 'colors'|'size'>
) => {
  const {
    dark,   // is dark mode?
    media,  // prefers media mode?
    night,  // is it night? (in prefers media mode)
    toggle  // toggle dark callback
  } = useDarkThemeCtx();  // checks both LocalStorage.theme and prefers-color-scheme
  const stopCls = "transition-colors duration-200";
  const len = {  // sun and moon icon sizes
    sm: { light: 20, dark: 16 },
    md: { light: 24, dark: 20 },
    lg: { light: 28, dark: 24 },
  };
  return (
    <span {...cLo("flex items-center", cn)}>
      <ToggleDot
        bgcolors={{
          on: "bg-gray-200",
          off: night ? "bg-red-500" : "bg-green-400"
        }}
        isSelected={media}
        onPress={() => toggle(media)}
      />
      <ToggleSlider
        isDisabled={media}
        isSelected={dark}
        onPress={() => toggle()}
        srOnly="Dark mode switch"
        colors={media ? cL(colors, "opacity-50") : colors}
        size={size}
      >
        <SvgIconFull
          {...cLo(stopCls, "mx-1 dark:text-yellow-200 text-gray-500")}
          {...squareView(len[size].dark)}
          Path="moon_dark"
          fill="currentColor"
        />
        <svg {...squareView(len[size].light, 32)} fill="none">
          <path {...RULE_EVENODD} fill="url(#lG_light)" d={getPathD("sun_light")} />
          <defs>
            <linearGradient id="lG_light" x1="2" y1="2" x2="30" y2="30" gradientUnits="userSpaceOnUse">
              <stop className={stopCls} stopColor={dark ? "#d4d4d8" : "#FACC15"} />
              <stop className={stopCls} stopColor={dark ? "#d4d4d8" : "#FA9D16"} offset="1" />
            </linearGradient>
          </defs>
        </svg>
      </ToggleSlider>
    </span>
  );
}

// button - needs customization for the following non-default tailwind classes
//  focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-white focus-visible:ring-gray-500
//  button style={{backgroundColor: dark ? "rgb(17,24,39)" : "rgb(96,211,96)"}}
