/*=============================================================================
 common-ui.tsx - Common UIs in React

 - [2021-02-26] React Aria, toggleButton isSelected, isPressed
 - ../node_modules/@react-types/button/src/index.d.ts

 (C) 2020 SpacetimeQ INC.
=============================================================================*/
import { useState, useRef } from 'react';
import { cL, cLo, showAttr } from 'utils/util';
import { IMouseProps } from 'common';
import { RULE_EVENODD, viewBox, TPathDataKey, getPathD, SvgIcon, SvgIconToggle } from 'utils/svg';
import { useToggleState, }                         from '@react-stately/toggle';
import { useButton, useToggleButton, }             from '@react-aria/button';
import { AriaButtonProps, AriaToggleButtonProps, } from '@react-types/button';

export const twButtonPressed = (isPressed: boolean) => isPressed && "border border-red-400";

export const ButtonPurple = ({ className: cn, ...props }:
  Partial<IClassNameObj> &
  AriaButtonProps<React.ElementType>
) => {
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps, isPressed } = useButton(props, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("flex-shrink-0 bg-purple-600 text-base font-bold py-2 px-4",
        "rounded-lg shadow-md hover:bg-purple-700 focus:outline-none focus:ring-2",
        "focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-purple-200 text-white", cn,
        twButtonPressed(isPressed)
      )}
    >
      {props.children}
    </button>
  );
}

// Extract the behavior to close(hide) on click as a hook
// When you need to keep the layout and just hide the window, use "invisible"
// - Dependencies: .Ani_dialog / .Ani_dialog_close in App.scss
// Usage) const { display, handleClose } = useClose("opacity-50");
// <div {...cLo("...", display)} onClick={handleClose} >
type TOpVariant = 0|5|10|20|25|30|40|50|60|70|75|80|90|95|100;
export type THideAttr =
  | "hidden"
  | "invisible"
  | `opacity-${TOpVariant}`
  | "Ani";
export const useClose = (ha: THideAttr = "Ani") => {
  const [close, setClose] = useState(false);
  const handleClose = () => { setClose(!close); }
  return ({
    hide: (ha === "Ani")
      ? `Ani_dialog${close ? "_close" : ""}`  // css class
      : (close ? ha : ""),
    handleClose
  });
}

export type TIconKind = 'error'|'ok'|'warn'|'info';
export type TIconKindColors = { [K in TIconKind]: stringU };
// IconButton on the dialog; role="button" shows cursor-pointer
interface IIconButtonProps extends Partial<IClassNameObj>, AriaButtonProps<React.ElementType> {
  kind: TIconKind;
};
export const IconButton = ({
  kind,
  className: cn = "h-10 w-10 border-4",  // add height, width, text and border colors
  ...props
}: IIconButtonProps) => {
  const colors: TIconKindColors = {
    error: cL("text-red-500 border-red-500 hover:text-red-700 hover:border-red-700",
         "dark:text-yellow-400 dark:border-yellow-400 dark:hover:text-white dark:hover:border-white"),
    ok:    cL("text-green-500 border-green-500 hover:text-green-700 hover:border-green-700",
         "dark:text-white dark:border-white dark:hover:text-white dark:hover:border-white"),
    warn:  cL("text-yellow-500 border-yellow-500 hover:text-yellow-700 hover:border-yellow-700",
         "dark:text-white dark:border-white dark:hover:text-white dark:hover:border-white"),
    info:  cL("text-blue-500 border-blue-500 hover:text-blue-700 hover:border-blue-700",
         "dark:text-white dark:border-white dark:hover:text-white dark:hover:border-white"),
  };
  const ref = useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("flex items-center justify-center flex-shrink-0 rounded-full hover:shadow-md",
        "transition duration-300 ease-in-out transform hover:scale-125 focus:outline-none",
        colors[kind], cn
      )}
    >
      <svg className="w-6 h-6 fill-current" {...viewBox(20)}>
        <path {...RULE_EVENODD} d={getPathD(("ico_" + kind) as TPathDataKey)} />
      </svg>
    </button>
  );
}
/* &times; */

export const CloseButton = ({
  className: cn = "h-4 w-4 border",
  ...props
}: Partial<IClassNameObj> &
  AriaButtonProps<React.ElementType>) =>
    <IconButton
      kind="error"
      {...props}
      {...cLo("absolute top-0 right-0 mt-1 mr-1 opacity-50 hover:opacity-100", cn)}
    />;

export const SpinningCircle = ({
  className: cn = "h-10 w-10 text-white"
}: Partial<IClassNameObj>) =>
  <svg
    {...cLo("animate-spin -ml-1 mr-3", cn)}
    fill="none"
    {...viewBox(24)}
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
    <path className="opacity-75" fill="currentColor" d={getPathD("arc_spin")} />
  </svg>;

// =========================================
// Tailwind Colorset Definitions
// =========================================
// colorset Text, Focus
export const tcsTextFocus = cL(
  "dark:text-gray-300 dark:hover:text-white",
  "text-gray-700 hover:text-black",
  "dark:focus:outline-none dark:focus:text-white dark:focus:bg-gray-700",
  "focus:outline-none focus:text-black focus:bg-gray-300"
);
// =========================================

export type TAngleRotateProps = 'L'|'R'|'U'|'D'; // Left, Right, Up, Down
export type TRightAngles      = 0|90|180|-90;
// direction to angle (degree)
export const dirToAngle = (dir: TAngleRotateProps) => {
  const ANGLE_ROTATION: Record<TAngleRotateProps, TRightAngles> = {
    'L': 0,
    'R': 180,
    'U': 90,
    'D': -90,
  };
  return ANGLE_ROTATION[dir];
}

// With the left arrow, generate 4 directional arrows
interface IButtonRotateProps extends IMouseProps {
  Path?: TPathDataKey;
  dir?:  TAngleRotateProps;
  fill?: string;
};
export const ButtonRotate = ({
  Path = "arrow_circle",
  dir,
  fill,
  onClick
}: IButtonRotateProps) => {
  return (
    <button className="p-1 focus:outline-none hover:text-yellow-600" onClick={onClick}>
      <SvgIcon
        className="w-5 h-5"
        {...{Path}}
        {...(dir && { Rotate: dirToAngle(dir) })}
        {...(fill && { fill })}
      />
    </button>
  );
}

interface IToggleButtonTextProps extends AriaToggleButtonProps<React.ElementType> {
  textOn:   string;
  textOff?: string;
};
export function ToggleButtonText({
  textOn, textOff,
  ...props
}: IToggleButtonTextProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("inline-flex p-1 items-center justify-center", tcsTextFocus,
        twButtonPressed(isPressed))}
    >
      {state.isSelected ? textOn : textOff}
    </button>
  );
}

// << button
interface ISideArrowButtonProps extends AriaToggleButtonProps<React.ElementType> {
  dir?: TAngleRotateProps;
};
export function SideArrowButton({
  dir = 'L',
  ...props
}: ISideArrowButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const angle = dirToAngle(dir);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("border-2 border-transparent rounded-full", tcsTextFocus,
        twButtonPressed(isPressed))}
    >
      <SvgIcon Rotate={angle} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
    </button>
  );
}

interface INotiIconButtonProps extends AriaToggleButtonProps<React.ElementType> {
  notis?: number;  // number of unread notifications
};
export function NotiIconButton({
  notis = 0,
  ...props
}: INotiIconButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  props.isSelected = notis > 0;
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  const onStyle = {
    stroke:      "red",
    strokeWidth: .5,
    fill:        "pink",
  };
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("p-1 border-transparent rounded-full", tcsTextFocus,
        twButtonPressed(isPressed))}
    >
      <SvgIcon {...(state.isSelected && onStyle)} Path="bell_noti" />
      <span
        {...cLo("fixed top-2 ml-3 bg-red-700 text-white rounded-full w-3 h-3 shadow-md text-2xs",
          showAttr(notis > 0))}
      >
        {notis}
      </span>
    </button>
  );
}

// Create both on and off state svg and then switch the show and hide attribute only
// Make sure just the class= toggles not the <svg itself using the Inspector
// is Menu On (shown)?
export function BurgerButton(props: AriaToggleButtonProps<React.ElementType>) {
  const ref = useRef<HTMLButtonElement>(null);  // null is required in TypeScript
  const state = useToggleState(props);
  const { buttonProps, isPressed } = useToggleButton(props, state, ref);
  return (
    <button
      {...{ref}}
      {...buttonProps}
      {...cLo("inline-flex p-1 items-center justify-center", tcsTextFocus,
        twButtonPressed(isPressed))}
    >
      <SvgIconToggle
        cond={state.isSelected}
        d="M6 18L18 6M6 6l12 12"
        d2="M4 6h16M4 12h16M4 18h16"
      />
    </button>
  );
}

export const Copyright = () =>
  <p {...cLo("mt-2 text-2xs text-right font-serif italic text-center tracking-widest")}>
    <span className="text-2xs text-gray-400">(C) {new Date().getFullYear()} </span>
    <span className="text-blue-700">Space</span>
    <span className="text-green-700">time</span>
    <span className="text-red-700">Q</span>
    <span className="text-3xs text-gray-400"> INC</span>
  </p>;

export const consoleLogo = (
  title: string,
  contact: string = "Q@spacetimeq.com"
) => {
  const BGC  = 'background:#ffffe0;';
  const C_R  = `color:red;${BGC}`;
  const C_G  = `color:green;${BGC}`;
  const C_B  = `color:blue;${BGC}`;
  const C_B2 = 'color:royalblue;';
  const C_C  = 'font-family:monospace;color:blue;';
  const C_N  = 'color:darkgray';
// ASCII ART: http://patorjk.com/software/taag/#p=display&f=Graffiti&t=Type%20Something%20
  console.log("%c╔═╗┌─┐┌─┐┌─┐%c┌─┐┌┬┐┬┌┬┐┌─┐%c╔═╗  %c" + title + " - contact:%c" + contact + '%c',
              C_B,          C_G,           C_R,   C_N,                      C_C,             C_N);
  console.log("%c╚═╗├─┘├─┤│  %c├┤  │ ││││├┤ %c║═╬╗ %c" + Date() + '%c',
              C_B,          C_G,           C_R,   C_B2,           C_N);
  console.log("%c╚═╝┴  ┴ ┴└─┘%c└─┘ ┴ ┴┴ ┴└─┘%c╚═╝╚ %c(C) %cSpace%ctime%cQ%c INC",
              C_B,          C_G,           C_R,    C_N, C_B,   C_G, C_R,C_N);
};
